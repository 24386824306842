import { createSlice } from '@reduxjs/toolkit'
import { userState } from '../initialStates';

const userSlice = createSlice({
  name: "user",
  initialState: userState,
  reducers: {
    login: (state, action) => {
      state.token = action.payload
    },
    basicDet: (state, action) => {
      state.basicDetails = action.payload
    },
  }
});
export const { login, basicDet } = userSlice.actions
export default userSlice.reducer