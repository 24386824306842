import {createStore} from 'redux'
import rootReducer from './reducers/rootReducer'
import {persistStore, persistReducer} from 'redux-persist'
import {userState, masterDataState, contentProviderState} from './initialStates';
import storage from 'redux-persist/lib/storage'

const persistConfig = {
    key: 'root',
    storage,
}

const configureStore = preLoadedState => {
    const mainReducer = (state, action) => {
        if (action.type === 'RESET_APP') {
            state = preLoadedState;
        }
        return rootReducer(state, action);
    }
    const persistedReducer = persistReducer(persistConfig, mainReducer)
    return createStore(
        persistedReducer,
        window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
    )
}

export default configureStore

export const store = configureStore({user: userState, masterData: masterDataState,contentProvider: contentProviderState});
export const persistor = persistStore(store)