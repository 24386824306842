import {createSlice} from '@reduxjs/toolkit'
import {masterDataState} from '../initialStates';

const masterDataSlice = createSlice({
    name: "masterData",
    initialState: masterDataState,
    reducers: {
        setUserData: (state, action) => {
            state.userData.data = action.payload['results']
            state.userData.total = action.payload['count']
            state.userData.current = action.payload['current']
        },
        addUpdateUser: (state, action) => {
            let index = state.userData.data.findIndex((obj) => obj['id'] === action.payload['id'])
            if (index !== -1) {
                state.userData.data[index] = action.payload
            } else {
                state.userData.data.push(action.payload)
            }
        },
        delUser: (state, action) => {
            let index = state.userData.data.findIndex((obj) => obj['id'].toString() === action.payload.toString())
            state.userData.data.splice(index, 1)
        },
        setAdmin: (state, action) => {
            state.admins.data = action.payload['results']
            state.admins.total = action.payload['count']
            state.admins.current = action.payload['current']
        },
        addUpdateAdmin: (state, action) => {
            let index = state.admins.data.findIndex((obj) => obj['id'] === action.payload['id'])
            if (index !== -1) {
                state.admins.data[index] = action.payload
            } else {
                state.admins.data.push(action.payload)
            }
        },
        deleteAdmin: (state, action) => {
            let index = state.admins.data.findIndex((obj) => obj['id'] === action.payload)
            state.admins.data.splice(index, 1)
        },
        setSubsData: (state, action) => {
            state.subs = action.payload
        },
        updatePlan: (state, action) => {
            let index = state.subs.findIndex((obj) => obj['id'] === action.payload['id'])
            state.subs[index] = action.payload
        },
        addPlan: (state, action) => {
            state.subs.push(action.payload)
        },
        deletePlan: (state, action) => {
            let index = state.subs.findIndex((obj) => obj['id'] === action.payload['id'])
            state.subs.splice(index, 1)
        },
        setMovieData: (state, action) => {
            state.movies.data = action.payload['results']
            state.movies.total = action.payload['count']
            state.movies.current = action.payload['current']
        },
        deleteMovie: (state, action) => {
            let index = state.movies.data.findIndex((obj) => obj['id'] === action.payload)
            state.movies.data.splice(index, 1)
            state.movies.total = state.movies.total - 1
        },
        deleteSeries: (state, action) => {
            let index = state.series.data.findIndex((obj) => obj['id'] === action.payload)
            state.series.data.splice(index, 1)
            state.series.total = state.movies.total - 1
        },
        setSeriesData: (state, action) => {
            state.series.data = action.payload['results']
            state.series.total = action.payload['count']
            state.series.current = action.payload['current']
        },
        userStats: (state, action) => {
            state.stats = action.payload
        },
        deleteSeasons: (state, action) => {
            let index = state.series.data.findIndex((obj) => obj['id'] === action.payload['series'])
            let index2 = state.series.data[index].seasons.findIndex((obj) => obj['id'] === action.payload['id'])
            state.series.data[index].seasons.splice(index2, 1)
        },
        deleteEpisodes: (state, action) => {
            let index = state.series.data.findIndex((obj) => obj['id'] === action.payload['series'])
            let index2 = state.series.data[index].seasons.findIndex((obj) => obj['id'] === action.payload['season'])
            let index3 = state.series.data[index].seasons[index2].episode.findIndex((obj) => obj['id'] === action.payload['id'])
            state.series.data[index].seasons[index2].episode.splice(index3, 1)
        },
        saveSeason: (state, action) => {
            let index = state.series.data.findIndex((obj) => obj['id'] === action.payload['series'])
            let index2 = state.series.data[index].seasons.findIndex((obj) => obj['id'] === action.payload['id'])
            if (index2 !== -1) {
                state.series.data[index].seasons.splice(index2, 1, action.payload)
            } else {
                state.series.data[index].seasons.push(action.payload)
            }
        },
          setPremieres: (state, action) => {
              if(action.payload['tab'] === 'movie') {
                  state.premieres.movies.data = action.payload['results']
                  state.premieres.movies.total = action.payload['count']
                  state.premieres.movies.current = action.payload['current']
              }
              if(action.payload['tab'] === 'series') {
                state.premieres.series.data = action.payload['results']
                state.premieres.series.total = action.payload['count']
                state.premieres.series.current = action.payload['current']
              }
        }
    }
})
export const {
    setUserData, delUser,
    setAdmin, addUpdateAdmin, deleteAdmin,
    setSubsData, updatePlan, addPlan, deletePlan,
    setMovieData, deleteMovie,
    setSeriesData, deleteSeries, userStats,
    addUpdateUser, deleteSeasons, deleteEpisodes, saveSeason, setPremieres
} = masterDataSlice.actions
export default masterDataSlice.reducer