import {combineReducers} from 'redux'
import masterDataSlice from './masterDataSlice'
import userSlice from './userSlice'
import contentProviderSlice from "./contentProviderSlice";

const rootReducer = combineReducers({
    user: userSlice,
    masterData: masterDataSlice,
    contentProvider: contentProviderSlice
})

export default rootReducer