import React, {lazy} from 'react';
import {Redirect, Route, Switch} from "react-router-dom";

const Header = lazy(() => import("./components/navigation/header"));
const Dashboard = lazy(() => import("./components/dashboard/index"));
const Libraries = lazy(() => import("./components/libraries/index"));
const MovieData = lazy(() => import("./components/libraries/movies/movieData"));
const SeriesData = lazy(() => import("./components/libraries/series/seriesData"));


const AdminRoutes = ({theme, toggleTheme}) => {
    return (
        <Switch>
            <Route
                exact
                path="/libraries/movieData"
                render={(props) => (
                    <MovieData
                        {...props}
                        component={MovieData}
                        title="Movie | Stay Connected TV"
                    />
                )}
            />
            <Route
                exact
                path="/libraries/seriesData"
                render={(props) => (
                    <>
                        <SeriesData
                            {...props}
                            theme={theme}
                            toggleTheme={toggleTheme}
                            component={SeriesData}
                            title="Series | Stay Connected TV"
                        />
                    </>
                )}
            />
            <div>
                <Header theme={theme} toggleTheme={toggleTheme}/>
                <Switch>
                    <Route
                        exact
                        path="/dashboard"
                        render={(props) => (
                            <Dashboard
                                {...props}
                                component={Dashboard}
                                title="Dashboard | Stay Connected TV"
                            />
                        )}
                    />
                    <Route
                        exact
                        path="/libraries"
                        render={(props) => (
                            <Libraries
                                {...props}
                                component={Libraries}
                                title="Libraries | Stay Connected TV"
                            />
                        )}
                    />
                    <Route
                        path="/"
                        render={(props) => <Redirect {...props} to="/dashboard"/>}
                    />
                </Switch>
            </div>
        </Switch>
    );
};

export default AdminRoutes;