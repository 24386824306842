import React, {lazy, useEffect, useState, Suspense} from "react";
import {
    BrowserRouter as Router,
    Redirect,
    Route,
    Switch,
} from "react-router-dom";
import {GlobalStyles, lightTheme, darkTheme} from "./styles/globalStyles";
import {ThemeProvider} from "styled-components";
import {login} from "./store/reducers/userSlice";
import LoadingScreen from "./components/auth/LoadingScreen";
import {checkValidity} from "./config/apis";
import {connect} from "react-redux";
import SuperRoutes from "./superRoutes";
import AdminRoutes from "./adminRoutes";

const Login = lazy(() => import("./components/auth/login"));
const ForgotPassword = lazy(() => import("./components/auth/forgotPassword"));


const useDarkMode = () => {
    const [theme, setTheme] = useState("light");
    const setMode = (mode) => {
        window.localStorage.setItem("theme", mode);
        setTheme(mode);
    };
    const toggleTheme = () => {
        theme === "dark" ? setMode("light") : setMode("dark");
    };
    useEffect(() => {
        const localTheme = window.localStorage.getItem("theme");
        localTheme ? setTheme(localTheme) : setMode("light");
    }, []);
    return [theme, toggleTheme];
};

function Main(props) {
    const [theme, toggleTheme] = useDarkMode();
    const [validating, setValidating] = useState(true);
    useEffect(() => {
        if (props.token !== null) {
            checkValidity(props.token).then((status) => {
                if (status === false) {
                    props.reset();
                } else {
                    props.login(status);
                }
                setTimeout(() => {
                    setValidating(false);
                }, 10);
            });
        } else {
            setValidating(false);
        }
    }, []);
    const themeMode = theme === "light" ? lightTheme : darkTheme;
    if (validating) {
        return <LoadingScreen/>;
    }
    return (
        <ThemeProvider theme={themeMode}>
            <GlobalStyles/>
            <Suspense fallback={<LoadingScreen/>}>
                <Router>
                    {props.token === null ? (
                            <Switch>
                                <Route
                                    exact
                                    path="/"
                                    render={(props) => (
                                        <Login
                                            {...props}
                                            theme={theme}
                                            toggleTheme={toggleTheme}
                                            title="Login | Stay Connected TV"
                                        />
                                    )}
                                />
                                <Route
                                    exact
                                    path="/forgotPassword"
                                    render={(props) => (
                                        <ForgotPassword
                                            {...props}
                                            theme={theme}
                                            toggleTheme={toggleTheme}
                                            title="Forgot Password | Stay Connected TV"
                                        />
                                    )}
                                />
                                <Route
                                    path="/"
                                    render={() => {
                                        return <Redirect to="/"/>;
                                    }}
                                />
                            </Switch>
                        ) :
                        props.basicDetails.user.is_superuser ?
                            <SuperRoutes theme={theme} toggleTheme={toggleTheme}/>
                            :
                            <AdminRoutes theme={theme} toggleTheme={toggleTheme}/>
                    }
                </Router>
            </Suspense>
        </ThemeProvider>
    );
}

const mapStateToProps = (state) => {
    return {
        token: state.user.token,
        basicDetails: state.user.basicDetails,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        reset: () => dispatch({type: "RESET_APP"}),
        login: (data) => dispatch(login(data)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Main);
