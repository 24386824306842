import {createSlice} from '@reduxjs/toolkit'
import {contentProviderState} from '../initialStates';

const contentProviderSlice = createSlice({
    name: "contentProvider",
    initialState: contentProviderState,
    reducers: {
        setProvider: (state, action) => {
            state.provider.data = action.payload['results']
            state.provider.total = action.payload['count']
            state.provider.current = action.payload['current']
        },
        addUpdateProvider: (state, action) => {
            let index = state.provider.data.findIndex((obj) => obj['id'] === action.payload['id'])
            if (index !== -1) {
                state.provider.data[index] = action.payload
            } else {
                state.provider.data.push(action.payload)
            }
        },
        deleteProvider: (state, action) => {
            let index = state.provider.data.findIndex((obj) => obj['id'] === action.payload)
            state.provider.data.splice(index, 1)
        },
    }
});
export const {setProvider, addUpdateProvider, deleteProvider} = contentProviderSlice.actions
export default contentProviderSlice.reducer