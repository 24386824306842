export const userState = {
    token: null,
    basicDetails: {
        user: {}
    },
}

export const masterDataState = {
    userData: {
        pageSize: 10,
        current: 1,
        data: [],
        total: 0
    },
    subs: [],
    movies: {
        pageSize: 10,
        current: 1,
        data: [],
        total: 0
    },
    series: {
        pageSize: 10,
        current: 1,
        data: [],
        total: 0
    },
    admins: {
        pageSize: 10,
        current: 1,
        data: [],
        total: 0
    },
    stats: {
        top4: [],
        latest4: []
    },
    premieres: {
        movies: {
            pageSize: 10,
            current: 1,
            data: [],
            total: 0
        },
        series: {
            pageSize: 10,
            current: 1,
            data: [],
            total: 0
        }
    }
}

export const contentProviderState = {
    provider: {
        pageSize: 10,
        current: 1,
        total:0,
        data: [],
    },
}

