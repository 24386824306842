import React, {lazy} from 'react';
import {Redirect, Route, Switch} from "react-router-dom";
const Header = lazy(() => import("./components/navigation/header"));
const Dashboard = lazy(() => import("./components/dashboard/index"));
const Users = lazy(() => import("./components/users/index"));
const AddNewUser = lazy(() => import("./components/users/addNewUser"));
const Libraries = lazy(() => import("./components/libraries/index"));
const MovieData = lazy(() => import("./components/libraries/movies/movieData"));
const SeriesData = lazy(() => import("./components/libraries/series/seriesData"));
const Subscriptions = lazy(() => import("./components/subscription/index"));
const Admin = lazy(() => import("./components/admin/index"));
const ContentProvider = lazy(() => import("./components/contentProvider"));
const AddNewContentProvider = lazy(() =>
    import("./components/contentProvider/addNewContentProvider")
);
const Approvals = lazy(() => import("./components/approvals/index"));
const WalletTransaction = lazy(() =>
    import("./components/walletTransaction/walletTransaction")
);
const Premiere = lazy(() => import("./components/premiere"))

const SuperRoutes = ({theme,toggleTheme}) => {
    return (
        <Switch>
            <Route
                exact
                path="/users/user"
                render={(props) => (
                    <>
                        <AddNewUser
                            {...props}
                            component={AddNewUser}
                            title="User Info | Stay Connected TV"
                        />
                    </>
                )}
            />
            <Route
                exact
                path="/libraries/movieData"
                render={(props) => (
                    <MovieData
                        {...props}
                        component={MovieData}
                        title="Movie | Stay Connected TV"
                    />
                )}
            />
            <Route
                exact
                path="/libraries/seriesData"
                render={(props) => (
                    <>
                        <SeriesData
                            {...props}
                            theme={theme}
                            toggleTheme={toggleTheme}
                            component={SeriesData}
                            title="Series | Stay Connected TV"
                        />
                    </>
                )}
            />
            <Route
                exact
                path="/content_provider/contentProvider"
                render={(props) => (
                    <>
                        <AddNewContentProvider
                            {...props}
                            component={AddNewContentProvider}
                            title="Content Provider Info | Stay Connected TV"
                        />
                    </>
                )}
            />
            <Route
                exact
                path="/users/walletTransaction/:id"
                render={(props) => (
                    <>
                        <WalletTransaction
                            {...props}
                            theme={theme}
                            toggleTheme={toggleTheme}
                            component={SeriesData}
                            title="Wallet Transaction Details | Stay Connected TV"
                        ></WalletTransaction>
                    </>
                )}
            />
            <div>
                <Header theme={theme} toggleTheme={toggleTheme}/>
                <Switch>
                    <Route
                        exact
                        path="/dashboard"
                        render={(props) => (
                            <Dashboard
                                {...props}
                                component={Dashboard}
                                title="Dashboard | Stay Connected TV"
                            />
                        )}
                    />
                    <Route
                        exact
                        path="/users"
                        render={(props) => (
                            <Users
                                {...props}
                                component={Users}
                                title="Users | Stay Connected TV"
                            />
                        )}
                    />
                    <Route
                        exact
                        path="/libraries"
                        render={(props) => (
                            <Libraries
                                {...props}
                                component={Libraries}
                                title="Libraries | Stay Connected TV"
                            />
                        )}
                    />
                    <Route
                        exact
                        path="/subscriptions"
                        render={(props) => (
                            <Subscriptions
                                {...props}
                                theme={theme}
                                toggleTheme={toggleTheme}
                                component={Subscriptions}
                                title="Subscriptions | Stay Connected TV"
                            />
                        )}
                    />
                    <Route
                        exact
                        path="/admin"
                        render={(props) => (
                            <Admin
                                {...props}
                                theme={theme}
                                toggleTheme={toggleTheme}
                                component={Admin}
                                title="Admin | Stay Connected TV"
                            />
                        )}
                    />
                    <Route
                        exact
                        path="/content_provider"
                        render={(props) => (
                            <ContentProvider
                                {...props}
                                theme={theme}
                                toggleTheme={toggleTheme}
                                component={ContentProvider}
                                title="Content Provider | Stay Connected TV"
                            />
                        )}
                    />
                    <Route
                        exact
                        path="/approvals"
                        render={(props) => (
                            <Approvals
                                {...props}
                                theme={theme}
                                toggleTheme={toggleTheme}
                                component={Approvals}
                                title="Approvals | Stay Connected TV"
                            />
                        )}
                    />
                    <Route
                        exact
                        path="/premiere"
                        render={(props) => (
                            <Premiere
                                {...props}
                                theme={theme}
                                toggleTheme={toggleTheme}
                                title="Premiere | Stay Connected TV"
                            />
                        )}
                    />
                    <Route
                        path="/"
                        render={(props) => <Redirect {...props} to="/dashboard"/>}
                    />
                </Switch>
            </div>
        </Switch>
    );
};

export default SuperRoutes;